<template>
    <div>
        <div>
            <div class="tela-novo-pedido" style='display:none'>
                <div class='box-bell'>
                    <img class="bell" src="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/img/bell.png"/>
                </div>
            </div>

            <div id="loader-novo-pedido" style ="display:none;">
                <div class="bg">
                </div>
                <div class="logo">
                    <div class="animacao">
                        <img class="img-responsive" alt="Logo" src="https://cdn.finer.com.br/imagens/foods/LogoOficialFinerFoods.png">
                        <h3 style="text-align: center;">NOVO PEDIDO: <span class="codigo_pedido"></span></h3>
                    </div>
                </div>
            </div>

            <div id="loader-pedido-atualizado" style ="display:none;">
                <div class="bg">
                </div>
                <div class="logo">
                    <div class="animacao">
                        <img class="img-responsive" alt="Logo" src="https://cdn.finer.com.br/imagens/foods/LogoOficialFinerFoods.png">
                        <h3 style="text-align: center;">PEDIDO ATUALIZADO: <span class="codigo_pedido_atualizado"></span></h3>
                    </div>
                </div>
            </div>
        </div>

        <header>
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/bootstrap-responsive.css" rel="stylesheet">
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/bootstrap.css" rel="stylesheet">
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/operacao/frente-cozinha.css" rel="stylesheet">
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/owl.carousel.min.css" rel="stylesheet">
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/owl.theme.default.min.css" rel="stylesheet">
            <link href="https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/jquery-ui.css" rel="stylesheet">
            <div class="row row-header">
                <div class="col-md-3 logo">
                    <div class='logo-content pull-left img' style='background: url("https://d2lbnca5brskj0.cloudfront.net/finerdev/assets/bootstrap/css/image/logo-finer.png") no-repeat scroll 0% 0% transparent; height: 44px; width: 52px; '>
                    </div>
                    <!-- <h1><?php echo $this->CI->getModeloSistema(); ?></h1> -->
                </div>
                <div class="col-md-5 meio">
                    <div class="row row-meio">
                        <div id="div-data">
                        </div>
                        <div id="div-hora">                            
                        </div>
                    </div>
                </div>
                <div class="col-md-4 buttons direita pull-right">
                    <div class="pull-right nav-buttons">
                        <div class="div-ifood-loja-aberta" style="display:none">
                            <span class="titulo-aberto"></span>
                        </div>
                        <a href="<?php echo base_url('index.php/admin/'); ?>" title="Voltar ao sistema" rel="tooltip">
                            <i class="fa fa-home" aria-hidden="true"></i>
                        </a>
                        <!-- <?php if ($this->CI->getUser()['usuario_grupo_id'] == '1') : ?> -->
                            <button id="btn-cupom" title="Cupons" rel="tooltip" onclick="listaCupons(true)">
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                            </button>
                        <!-- <?php endif ?> -->
                        <button id="notificacoes" title="Notificações" rel="tooltip">
                            <i style="font-size: 20px;" class="fa fa-bell" aria-hidden="true"></i>
                            <span id="span-notificacoes"></span>
                        </button>
                        <div class="div-notificacoes"></div>
                    </div>
                </div>
                <div style='clear:both'></div>
            </div>   
        </header>

        <section class="container-cozinha">
            <div class="row">    
                <div class="col-md-12 div-table">
                    <table id="table-delivery" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                        <thead>
                            <tr>
                                <th>Nº Delivery/Código Display</th>
                                <th>Marketplace</th>
                                <th>Nº Pedido</th>
                                <th>Cliente</th>
                                <th>Situação</th>
                                <th>Pago</th>
                                <th>Data e Hora</th>
                                <th class="all">Opções</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </section>

        <div id="modal-cupons" class="modal-cupom modal fade in" >
            <div class="modal-dialog" style="margin: 5vw">
                <div class="modal-content" style="width: 90vw">
                    <div class="modal-header">
                        <div class="titulo">
                            <h3>Cupons</h3>
                            <button id="btn-close-modal-cupom" type="button" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="btn-adicionar">
                            <button id="btn-modal-adicionar-cupom">
                                <i class="loader-spinner fas fa-circle-notch fa-spin"></i>
                                Adicionar cupom
                            </button>
                        </div>
                        <div class="filtros">
                            <div class="filtro-data">
                                <input id="data-de" class="form-control" type="date">
                                <input id="data-ate" class="form-control data-ate" type="date">
                                <button id="btn-filtrar" class="form-control" onclick="filtrarCupons()">
                                    Filtrar
                                </button>
                            </div>
                            <div class="filtro-status">
                                <span class="btn-cupons-ativos active" onclick="listaCupons(true)">Ativos </span>
                                <span class="separador">|</span>
                                <span class="btn-cupons-finalizados" onclick="listaCupons(false)"> Finalizados</span>
                            </div>
                        </div>                   
                    </div>
                    <div class="div-inputs">
                        <div class="row">
                            <table id="table-cupons" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cód. Cupom</th>
                                        <th>Quantidade</th>
                                        <th>Qnt. por usuário</th>
                                        <th>Preço mínimo</th>
                                        <th>Tipo desconto</th>
                                        <th>Valor desconto</th>
                                        <th>Data início</th>
                                        <th>Data validade</th>
                                        <th>Primeira compra?</th>
                                        <th>Status</th>
                                        <th class="all">Opções</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="modal-adicionar-cupom" class="modal-cupom modal fade in">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Adicionar Cupom</h3>
                        <label> *Campos obrigatórios</label>
                        <button type="button" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <form id="form-adicionar-cupom">
                        <div class="div-inputs">
                            <div class="row">
                                <div class="col-6 div-criar-cupom">
                                    <label>Nome cupom*</label>
                                    <input id="nome_cupom" class="form-control" placeholder="Nome" type="text" required/>
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Código cupom*</label>
                                    <input id="codigo_cupom" class="form-control" placeholder="Código cupom" type="text" required />
                                </div>
                                <div class="col-6 div-criar-cupom busca-usuario">
                                    <label>Usuário</label>
                                    <input id="usuario_id" placeholder="Busca por nome" type="text" />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Quantidade de cupons</label>
                                    <input id="quantidade_usuarios" min="0" class="form-control" placeholder="Quantidade de cupons" type="number" />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Quantidade por usuários</label>
                                    <input id="quantidade_por_usuario" min="0" class="form-control" placeholder="Quantidade por usuários" type="number" />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Preço mínimo da compra</label>
                                    <input id="preco_minimo" class="form-control" placeholder="Preço mínimo da compra" type="text" />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Tipo de desconto*</label>
                                    <select id="tipo_desconto" class="form-control">
                                        <option value="1">Em reais</option>
                                        <option value="2">Em porcentagem</option>
                                    </select>
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Valor desconto*</label>
                                    <input id="valor_desconto" class="form-control" placeholder="Valor" type="text" required />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Data de início*</label>
                                    <input id="data_inicio" class="form-control" placeholder="Data início" type="date" required />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Data de validade</label>
                                    <input id="data_validade" class="form-control" placeholder="Data validade" type="date" />
                                </div>
                                <div class="col-6 div-criar-cupom">
                                    <label>Status*</label>
                                    <select id="status" class="form-control">
                                        <option value="true">Ativo</option>
                                        <option value="false">Desativado</option>
                                    </select>
                                </div>
                                <div class="col-6 div-criar-cupom primeira-compra">
                                    <label style="margin-bottom: 0;">Primeira compra?</label>
                                    <input id="primeira_compra" class="form-control" placeholder="Primeira compra" name="primeira_compra" type="checkbox" />
                                </div>
                            </div>
                        </div>
                        <div class="btn-adicionar">
                            <button id="btn-adicionar-cupom" style="margin-top: 15px;">
                                <i class="loader-spinner fas fa-circle-notch fa-spin"></i>
                                Adicionar
                            </button>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>

        <div id="modal-alterar-cupom" class="modal-cupom modal fade in">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Alterar Cupom</h3>
                        <label> *Campos obrigatórios</label>
                        <button type="button" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <form id="form-alterar-cupom">
                        <div class="div-inputs">
                            <div class="row">
                                <div class="col-12 div-criar-cupom busca-usuario">
                                    <label>Usuário</label>
                                    <input id="usuario_id2" placeholder="Busca por nome" type="text" />
                                </div>
                                <div class="col-12 div-criar-cupom">
                                    <label>Status*</label>
                                    <select id="status_cupom" class="form-control">
                                        <option value="true">Ativo</option>
                                        <option value="false">Desativado</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="btn-adicionar">
                            <button id="btn-alterar-cupom" style="margin-top: 15px">
                                <i class="loader-spinner fas fa-circle-notch fa-spin"></i>
                                Alterar
                            </button>
                        </div>                        
                    </form>
                </div>
            </div>
        </div>

        <div id="modal-cupom-utilizado" class="modal-cupom modal fade in">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Detalhes do Cupom</h3>
                        <button type="button" id="btn-close-modal-infos-cupom" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div id="infos-cupom">
                        <table id="table-infos-cupom" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                            <thead>
                                <tr>
                                    <th>Cód. Cupom</th>
                                    <th>Tipo desconto</th>
                                    <th>Valor desconto</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div id="modal-cancelar-pedido" class="modal-cupom modal fade in">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Recusar o Pedido</h3>
                        <button type="button" id="btn-close-modal-cancelar-pedido" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <form id="form-cancelar-pedido">
                        <div class="div-inputs">
                            <div class="row">
                                <div class="col-12 div-criar-cupom">
                                    <label>Motivo*</label>
                                    <select id="motivo_cancelar" class="form-control" required>
                                        <option value="">Selecione uma opção</option>
                                    </select>
                                </div>
                                <div class="col-12 div-criar-cupom">
                                    <label>Descrição*</label>
                                    <input id="descricao_cancelar" class="form-control" placeholder="Breve descrição do cancelamento" type="text" required />
                                </div>
                            </div>
                        </div>
                        <div class="btn-adicionar">
                            <button id="btn-cancelar-pedido" data-id="1" data-texto="tal" style="margin-top: 15px">
                                Salvar
                            </button>
                        </div>         
                    </form>
                </div>
            </div>
        </div>

        <div id="modal-pedido-cancelamento" class="modal-cupom modal fade in">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Recusar o Pedido</h3>
                        <button type="button" id="btn-close-modal-pedido-cancelamento" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="div-inputs">
                        <div class="row" style="justify-content: center;">
                            <img class="img-responsive" alt="Logo" src="https://cdn.finer.com.br/imagens/foods/LogoOficialFinerFoods.png">
                            <h3 style="text-align: center;">O cliente deseja cancelar o pedido N°: <span class="codigo_pedido_cancelamento"></span></h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 btn-visualizar">
                            <button id="btn-visualizar-cancelamento" class="selector-btn-show-pedido" data-id="0" style="margin-top: 15px">
                                Visualizar
                            </button>
                        </div>
                        <div class="col-md-4 btn-recusar">
                            <button id="btn-recusar-cancelamento" data-id="0" data-texto=" recusar o cancelamento do cliente" data-status="recusar" style="margin-top: 15px">
                                Recusar
                            </button>
                        </div>
                        <div class="col-md-4 btn-adicionar">
                            <button id="btn-aceitar-cancelamento" data-id="0" data-texto=" aceitar o cancelamento do cliente" data-status="aceitar" style="margin-top: 15px">
                                Aceitar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="modal_show_pedido" class="modal-cupom modal fade in">
            <div class="modal-dialog" style="max-width: 100% !important; width: 1000px !important">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Visalizador de Pedido</h3>
                        <button type="button" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <!-- <div class="row"> -->
                        <div role="tabpanel">
                            <ul class="nav nav-tabs nav-prod" role="tablist">
                                <li role="presentation" class="active"><a href="#pedido" aria-controls="pedido" role="tab" data-toggle="tab"><i class="fa fa-list-alt fa-3x nav-icon"></i><br>Dados do Pedido</a></li>
                                <li role="presentation"><a href="#cliente" aria-controls="cliente" role="tab" data-toggle="tab"><i class="fa fa-user fa-3x nav-icon"></i><br>Dados do Cliente</a></li>
                                <li role="presentation"><a href="#produtos" aria-controls="produtos" role="tab" data-toggle="tab"><i class="fa fa-shopping-cart fa-3x nav-icon"></i><br>Produtos</a></li>
                                <li role="presentation"><a href="#cupons" aria-controls="cupons" role="tab" data-toggle="tab"><i class="fa fa-ticket fa-3x nav-icon"></i><br>Cupons</a></li>
                            </ul>
                            <div class="tab-content">
                                <div role="tabpanel" class="tab-pane fade active in" id="pedido">
                                    <div class="row">
                                        <div class="col-md-2 div-criar-cupom">
                                            <label>Nº Delivery</label>
                                            <input id="identificador_pedido" class="form-control" placeholder="Nº Delivery" type="text" disabled />
                                        </div>
                                        <div class="col-md-2 div-criar-cupom">
                                            <label>Nº Display</label>
                                            <input id="numero_display" class="form-control" placeholder="Nº Display" type="text" disabled />
                                        </div>

                                        <div class="col-md-2 div-criar-cupom">
                                            <label>Nº Pedido</label>
                                            <input id="pedido_id" class="form-control" placeholder="Nº Pedido" type="text" disabled />
                                        </div>

                                        <div class="col-md-2 div-criar-cupom">
                                            <label>Agendado</label>
                                            <input id="agendado" class="form-control" placeholder="Agendado" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Tipo de Pedido</label>
                                            <input id="pedido_tipo_entrega" class="form-control" placeholder="Tipo de Entrega" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Previsão Data Início</label>
                                            <input id="data_inicio" class="form-control" placeholder="Data Início" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Previsão Data Fim</label>
                                            <input id="data_fim" class="form-control" placeholder="Data Fim" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Aceitar pedido às:</label>
                                            <input id="data_comecar_preparar" class="form-control" placeholder="Data Começar a preparar" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Status do Pedido</label>
                                            <input id="pedido_status" class="form-control" placeholder="Status do Pedido" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Forma de Pagamento</label>
                                            <input id="pedido_pagamento" class="form-control" placeholder="Forma de Pagamento" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Bandeira do Cartão</label>
                                            <input id="bandeira_cartao" class="form-control dinheiro" placeholder="Bandeira do Cartão" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Tipo de Pagamento</label>
                                            <input id="pedido_pagamento_tipo" class="form-control" placeholder="Tipo de Pagamento" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Mesa</label>
                                            <input id="mesa" class="form-control" placeholder="Mesa" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Pago</label>
                                            <input id="pago" class="form-control" placeholder="Pago" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Forma de Retirada</label>
                                            <input id="pedido_tipo_retirada" class="form-control" placeholder="Forma de Retirada" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Valor Frete</label>
                                            <input id="valor_frete" class="form-control dinheiro" placeholder="Valor Frete" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Valor Troco</label>
                                            <input id="valor_troco" class="form-control dinheiro" placeholder="Valor Troco" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Valor Taxa</label>
                                            <input id="valor_taxa" class="form-control dinheiro" placeholder="Valor Taxa" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Valor Total</label>
                                            <input id="valor_total" class="form-control dinheiro" placeholder="Valor Total" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="cliente">
                                    <div class="row">
                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Nome do Cliente</label>
                                            <input id="nome_cliente" class="form-control" placeholder="Nome do Cliente" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>CPF/CNPJ do Cliente</label>
                                            <input id="cpf_cliente" class="form-control" placeholder="CPF do Cliente" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Telefone do Cliente</label>
                                            <input id="telefone_cliente" class="form-control" placeholder="Telefone do Cliente" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>CEP</label>
                                            <input id="cep_endereco" class="form-control" placeholder="CEP" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Cidade</label>
                                            <input id="cidade_endereco" class="form-control" placeholder="Cidade" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Bairro</label>
                                            <input id="bairro_endereco" class="form-control" placeholder="Bairro" type="text" disabled />
                                        </div>

                                        <div class="col-md-6 div-criar-cupom">
                                            <label>Rua</label>
                                            <input id="rua_endereco" class="form-control" placeholder="Rua" type="text" disabled />
                                        </div>

                                        <div class="col-md-3 div-criar-cupom">
                                            <label>Número</label>
                                            <input id="numero_endereco" class="form-control" placeholder="Número" type="text" disabled />
                                        </div>

                                        <div class="col-md-12 div-criar-cupom">
                                            <label>Complemento</label>
                                            <textarea id="complemento_endereco" class="form-control" placeholder="Complemento" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="produtos">
                                    <div class="row">
                                        <div class="col-md-12 div-criar-cupom">
                                            <table id="table-produtos-pedido" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Nome</th>
                                                        <th>Qtde</th>
                                                        <th>Novo</th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" class="tab-pane fade" id="cupons">
                                    <div class="row">
                                        <div class="col-md-12 div-criar-cupom tabela-produto-cupons">
                                            <table id="table-cupons-pedido" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Tipo</th>
                                                        <th>Responsável</th>
                                                        <th>Valor</th>
                                                        <!-- <th>Produto</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div id="modal_show_pedido_produto" class="modal-cupom modal fade in">
            <div class="modal-dialog" style="max-width: 100% !important; width: 1000px !important">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Visualizador de Produto</h3>
                        <button type="button" class="btn-close-modal close" data-dismiss="modal" aria-label="Close" style="position: absolute; right: 20px; top: 20px;">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-md-3 div-criar-cupom">
                            <label>Nome do Produto</label>
                            <input id="nome_produto" class="form-control" placeholder="Nome do Produto" type="text" disabled />
                        </div>

                        <div class="col-md-3 div-criar-cupom">
                            <label>Quantidade</label>
                            <input id="quantidade" class="form-control" placeholder="Quantidade" type="text" disabled />
                        </div>

                        <div class="col-md-3 div-criar-cupom">
                            <label>Valor do Produto</label>
                            <input id="valor_produto" class="form-control dinheiro" placeholder="Valor do Produto" type="text" disabled />
                        </div>

                        <div class="col-md-3 div-criar-cupom">
                            <label>Valor s/ adicionais</label>
                            <input id="valor_sem_complemento" class="form-control dinheiro" placeholder="Valor s/ adicionais" type="text" disabled />
                        </div>

                        <div class="col-md-12 div-criar-cupom">
                            <label>Observação</label>
                            <textarea id="observacao" class="form-control" placeholder="Observação" disabled></textarea>
                        </div>

                        <div class="col-md-12 div-criar-cupom tabela-produto-complementos">
                            <table id="table-complementos-produtos-pedido" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Qtde</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                        <div class="col-md-12 div-criar-cupom tabela-produto-pizza" hidden>
                            <table id="table-pizza-produtos-pedido" class="table display table-hover selector-table-delivery tabelaDataTables" data-class=".selector-table-delivery" data-settings="0">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Tipo</th>
                                        <th>Qtde</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalFecharRestaurante" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="myModalLabel">Fechar Restaurante</h4>
                    </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Data de *</label>
                            <input name="data_de" id="data_de_fechar_restaurante" class="form-control modal-fechar-restaurante" type="datetime-local">                                    
                        </div>

                        <div class="col-md-6">
                            <label>Data ate *</label>
                            <input name="data_ate" id="data_ate_fechar_restaurante" class="form-control modal-fechar-restaurante" type="datetime-local">                                    
                        </div>

                        <div class="col-md-12">
                            <label>Descrição</label>
                            <input name="descricao" id="descricao_fechar_restaurante" class="form-control modal-fechar-restaurante" type="text">                                    
                        </div>
                    </div>
                </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button> -->
                        <input type="button" class="btn btn-success btn-rod selector-btn-salvar-fechar" value="Fechar">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	// import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	// import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	// import CustomTable from '@/components/CustomTable.vue'
	// importa o plugin de request 
	import Request from '@/plugins/request'

	// exporta o componente
	export default {
		// nome do componente
		name: 'Usuarios',
		// componentes filhos
		components: {
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um usuário
			dialog_usuario: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para os grupos
			grupos: [],
			// variável para as empresas
			empresas: [],
			// variável para criar/editar usuário
			usuario: {
				usuario_id: '',
				nome: '',
				email: '',
				senha: '',
				status: '',
				grupo_id: '',
				grupo_nome: '',
				empresa_id: '',
				empresa_nome: '',
				data_adicionado: '',
				data_atualizado: '',
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'usuario_id',
					text: 'ID',
					sortable: true,
				},
				{
					value: 'nome',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'email',
					text: 'E-mail',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'empresa.nome',
					text: 'Empresa',
					sortable: true,
				},
				{
					value: 'grupo.nome',
					text: 'Grupo',
					sortable: true,
				},
				{
					value: 'data_adicionado',
					text: 'Data adicionado',
					sortable: true,
				},
				{
					value: 'data_atualizado',
					text: 'Última atualização',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 10,
			},
		}),
		// funções deste componente
		methods: {
			// funções de início do componente
			async init(){
				// função para pegar as empresas
				this.getEmpresas()
				// função para pegar os grupos
				this.getGrupos()
			},
			// função para pegar as empresas
			async getEmpresas(){
				// faz a requisição para o back para coletar as empresas
				var resp = await Request({
					// rota da requisição
					route: 'admin/empresa/get_empresa_select'
				})
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.empresas = resp.data.dados
				}
			},
			// função para pegar os grupos
			async getGrupos(){
				// faz a requisição para o back para coletar os grupos
				var resp = await Request({
					// rota da requisição
					route: 'admin/grupo/get_grupo_select'
				})
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel grupos
					this.grupos = resp.data.dados
				}
			},
			// função para enviar um usuário
			async enviarUsuario(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_usuario.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do usuário
					let dados = await {
						// coleta o nome do usuário
						nome: await this.usuario.nome,
						// coleta o email do usuário
						email: await this.usuario.email,
						// coleta as empresas do usuário
						empresa: await this.usuario.empresa_id,
						// coleta o grupo do usuário
						grupo: await this.usuario.grupo_id,	
					}
					// caso exista um usuario_id 
					if(this.usuario.usuario_id){
						// coleta o status do usuário
						dados.status = await this.usuario.status == 'Ativo' ? true : false
						// coleta o id do usuário
						dados.usuario_id = await this.usuario.usuario_id
					}
					if(this.usuario.senha != ''){
						dados.senha = await this.usuario.senha
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postUsuarios', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						this.$swal({
                            icon:'success',
							text: 'Usuário ' + (this.usuario.usuario_id ? 'editado' : 'cadastrado') + ' com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true
                        })
						// mostra a mensagem
						this.closeUsuario()
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
				else{
					this.$swal({
						title: 'Atenção',
						icon:'warning',
						text: 'Preencha todos os campos obrigatórios!',
						showCancelButton: false,
						showCloseButton: false,
						showConfirmButton: true,
						confirmButtonText:'Ok'
					})
				}
			},
			// função para coletar um usuário para editar
			async editarUsuario(usuario_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um usuário passando o id 
				var resp = await store.dispatch('getUsuario', usuario_id)
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui os dados do usuário vindos do back à váriável local
					this.usuario.usuario_id = await resp.data.dados[0].usuario_id || ''
					this.usuario.nome = await resp.data.dados[0].nome || ''
					this.usuario.email = await resp.data.dados[0].email || ''
					this.usuario.grupo_id = await resp.data.dados[0].grupo_id || ''
					this.usuario.grupo_nome = await resp.data.dados[0].grupo_nome || ''
					this.usuario.empresa_id = await resp.data.dados[0].empresa_id || '' 
					this.usuario.empresa_nome = await resp.data.dados[0].empresa_nome || ''
					this.usuario.status = await resp.data.dados[0].status ? 'Ativo' : 'Inativo'
					// mostra a modal de creat/edit usuário
					this.dialog_usuario = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit usuário
			closeUsuario(){
				// fecha a modal
				this.dialog_usuario = false
				// limpa os dados locais do usuário
				this.usuario =  {
					usuario_id: '',
					nome: '',
					email: '',
					senha: '',
					repete_senha: '',
					grupo: '',
					empresa: '',
					status: '',
				}
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>


<style>
#tbody_cupom tr{
    font-size: 28px;
}

#tbody_cupom td{
    line-height: 28px;
}

.table_coz tr td{
    font-size: 15px !important;
}
</style>

<!-- Fontes -->
<style>
 @font-face { 
    font-family: 'Pacifico Bold Script';
    src: url('https://cdn.finer.com.br/fonts/pacifico/Pacifico-Regular.ttf')
}

/* Poppins */
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Thin.ttf') format('truetype'); 
    font-weight: 100;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype'); 
    font-weight: 200;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Light.ttf') format('truetype'); 
    font-weight: 300;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Regular.ttf') format('truetype'); 
    font-weight: 400;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Medium.ttf') format('truetype'); 
    font-weight: 500;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'); 
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Bold.ttf') format('truetype'); 
    font-weight: 700;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype'); 
    font-weight: 800;
}
@font-face {
    font-family: 'Poppins';
    src: url('https://cdn.finer.com.br/fonts/poppins/Poppins-Black.ttf') format('truetype'); 
    font-weight: 900;
}
</style>

<style type="text/css">

/* *{
    font-family: 'Poppins';
} */

header{
    background-color: #FFF;
    color: #000;
}

header .row-header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
}

header .logo{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
}

header h1{
    font-size: 18px;
    margin: 0;
}

.meio .row-meio{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.meio .row-meio #div-data span{
    font-weight: 600;
}

.meio .row-meio #div-hora{
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
}

.meio .row-meio #div-hora i{
    font-size: 20px;
    color: #FE5C1D;
    margin-right: 5px;
}

.nav-buttons{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.nav-buttons a{
    padding: 0 !important;
    width: max-content !important;
    margin-left: 30px !important;
}

.nav-buttons a:hover{
    text-decoration: none;
}

.nav-buttons a:hover i {
    color: #FE5C1D;
}


.nav-buttons i{
    font-size: 25px;
    color: #404040;
    transition: all ease .3s;
}

.nav-buttons button{
    position: relative;
    background-color: transparent;
    border: none;
    margin-left: 30px;
}

.nav-buttons button #span-notificacoes{
    position: absolute;
    background-color: #FE5C1D;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 600;
    color: #000;
    top: -5px;
    right: -3px;
    padding: 0px 4px;
}

.nav-buttons button i{
    font-size: 23px;
    transition: all ease .3s;
}

.nav-buttons button:hover i {
    color: #FE5C1D;
}

.div-table{
    padding: 0;
}

.div-notificacoes{
    position: absolute;
    background-color: #E5E5E5;
    border-radius: 10px;
    visibility: hidden;
    width: max-content;
    height: max-content;
    opacity: 0;
    padding: 10px 15px;
    z-index: -1;
    top: 25px;
    left: 0;
    transition: all ease .3s;
}

.div-notificacoes p{
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #BBBBBB;
    font-weight: 600;
    visibility: hidden;
    opacity: 0;
    transition: all ease .3s;
}

.div-notificacoes.active{
    visibility: visible;
    opacity: 1;
    z-index: 1;
}

.div-notificacoes.active p{
    visibility: visible;
    opacity: 1;
}

#table-delivery tbody, #table-cupons tbody{
    box-shadow: 0px 4px 6px 8px rgb(24 39 75 / 12%), 0px 8px 8px -4px rgb(24 39 75 / 8%);
}

#table-delivery thead, #table-cupons thead, #table-infos-cupom thead, #table-produtos-pedido thead, #table-complementos-produtos-pedido thead, #table-pizza-produtos-pedido thead, #table-cupons-pedido thead{
    background-color: #404040;
    color: #FFF;
    padding: 15px 0;
}

#table-delivery tbody tr, #table-cupons tbody tr, #table-infos-cupom tbody tr{
    transition: all ease .3s;
}

#table-delivery tbody tr td, #table-cupons tbody tr td, #table-infos-cupom tbody tr td{
    border: none;
    border-right: 1px solid #C9C9C9;
    vertical-align: middle;
    transition: all ease .3s;
}

#table-delivery tbody tr td:nth-last-child(1), #table-cupons tbody tr td:nth-last-child(1){
    border: none;
}

#table-infos-cupom tbody tr{
    border-left: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
}

#table-delivery tbody tr:nth-child(even), #table-cupons tbody tr:nth-child(even){
    background-color: #FFF;
}

#table-delivery tbody tr:hover, #table-cupons tbody tr:hover{
    background-color: #E0E0E0;
}

#table-delivery tbody tr:hover td, #table-cupons tbody tr:hover td{
    border-color: #FFF;
}

#table-delivery tr th, #table-cupons tr th{
    height: 60px;
    vertical-align: middle;
    text-transform: uppercase;
}

#table-delivery tr th:nth-child(1) , #table-cupons tr th:nth-child(1) {
    border-radius: 5px 0 0 0;
}            

#table-delivery tr th:nth-last-child(1) , #table-cupons tr th:nth-last-child(1) {
    border-radius: 0 5px 0 0;
}
            
#table-delivery tr td:nth-child(1), td:nth-child(3), td:nth-child(7){
    font-weight: 600;
    text-transform: uppercase;
}

#table-delivery tr td:nth-child(2) img, #table-cupons tr td:nth-child(2) img{
    width: 35px;
    margin-right: 5px;
}

#table-delivery tr td.novo-pedido, #table-delivery tr td:nth-child(7).pago, #table-cupons tr td:nth-child(7).pago, #table-produtos-pedido tr td.pago, #table-complementos-produtos-pedido tr td.pago, #table-pizza-produtos-pedido tr td.pago, #table-cupons-pedido tr td.pago{
    color: green;
    font-weight: 600;
    text-transform: uppercase;
}            
#table-delivery tr td.aguardando-conta{
    color: #000480;
    font-weight: 600;
    text-transform: uppercase;
}
#table-delivery tr td.nao-pago, #table-cupons tr td.nao-pago, #table-produtos-pedido tr td.nao-pago, #table-complementos-produtos-pedido tr td.nao-pago, #table-pizza-produtos-pedido tr td.nao-pago, #table-cupons-pedido tr td.nao-pago{
    color: #CE0000;
    font-weight: 600;
    text-transform: uppercase;
}

 #table-delivery tr td:nth-child(7) i{
    color: #404040;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    transition: all ease .3s;
}

#table-delivery tr td:nth-child(7) i:hover{
    color: #FE5C1D;
}

#table-delivery tr td:nth-child(8) span, #table-cupons tr td:nth-child(8) span{
    font-weight: 600;
}

#loader-novo-pedido .bg{
    position: absolute;
    background-color: #FFF;
    filter: blur(4px);
    -webkit-filter: blur(144px);
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
}
#loader-novo-pedido .logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;                
}

#loader-novo-pedido .logo .animacao{
    animation: fadein 1.5s infinite alternate;
}

#loader-novo-pedido .logo h3{
    color: #343434;
    font-weight: 600;
}

#loader-novo-pedido .logo h3 span{
    color: #343434;
    font-weight: 600;
}

#loader-pedido-atualizado .bg{
    position: absolute;
    background-color: #FFF;
    filter: blur(4px);
    -webkit-filter: blur(144px);
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
}
#loader-pedido-atualizado .logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;                
}

#loader-pedido-atualizado .logo .animacao{
    animation: fadein 1.5s infinite alternate;
}

#loader-pedido-atualizado .logo h3{
    color: #343434;
    font-weight: 600;
}

#loader-pedido-atualizado .logo h3 span{
    color: #343434;
    font-weight: 600;
}

@keyframes fadein {
    from{ 
        opacity: 0; 
        transform: scale(1.1);
    }
    to{
        opacity: 1;
        transform: scale(1.4);
    }
}

tr >th{
    text-align: center;
}

/* // MODAL CUPOM */
.modal-cupom .modal-dialog {
    max-width: 50%;
}
.modal-cupom .modal-dialog .modal-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    padding-bottom: 15px;
    border: none;
}
.modal-cupom .modal-dialog .modal-header h3 {
    font-weight: 700;
    margin: 0 15px 0 0;
}
.modal-cupom .modal-dialog .modal-header .close {
    top: 30px !important;
    right: 30px !important;
    transition: all ease 0.3s;
}
.modal-cupom .modal-dialog .modal-header .close i {
    color: #000;
    font-size: 30px;
}
.modal-cupom .modal-dialog .btn-recusar{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.modal-cupom .modal-dialog .btn-recusar button {
    display: flex;
    justify-content: center;
    background-color: red;
    border-color: red;
    border-radius: 5px;
    color: #FFF;
    font-weight: 600;
    padding: 8px 20px;
    transition: all ease .3s;
}
.modal-cupom .modal-dialog .btn-recusar button:hover {
    background-color: #FFF;
    color: red;
}    
.modal-cupom .modal-dialog .btn-adicionar{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.modal-cupom .modal-dialog .btn-adicionar button {
    display: flex;
    justify-content: center;
    background-color: #00af00;
    border-color: #00af00;
    border-radius: 5px;
    color: #FFF;
    font-weight: 600;
    padding: 8px 20px;
    transition: all ease .3s;
}
.modal-cupom .modal-dialog .btn-adicionar button:hover {
    background-color: #FFF;
    color: #00af00;
}      
.modal-cupom .modal-dialog .btn-visualizar{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.modal-cupom .modal-dialog .btn-visualizar button {
    display: flex;
    justify-content: center;
    background-color: #FF7D14;
    border-color: #FF7D14;
    border-radius: 5px;
    color: #FFF;
    font-weight: 600;
    padding: 8px 20px;
    transition: all ease .3s;
}
.modal-cupom .modal-dialog .btn-visualizar button:hover {
    background-color: #FFF;
    color: #FF7D14;
}       
.modal-cupom .modal-dialog .filtros{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 70%;
}
.modal-cupom .modal-dialog .filtros .filtro-data{
    display: flex;
}
.modal-cupom .modal-dialog .filtros .filtro-data .data-ate{
    margin-left: 15px;
}
.modal-cupom .modal-dialog .filtros .filtro-data button{
    width: max-content;
    margin-left: 15px;
    transition: all ease .3s;
}
.modal-cupom .modal-dialog .filtros .filtro-data button:hover{
    background-color: #E4E4E4;
}
.modal-cupom .modal-dialog .filtros span.separador{
    cursor: text;
}
.modal-cupom .modal-dialog .filtros span.separador:hover{
    color: #000;
}
.modal-cupom .modal-dialog .filtros span{
    cursor: pointer;
    transition: all ease .3s;
}
.modal-cupom .modal-dialog .filtros span.active{
    font-weight: 600;
}
.modal-cupom .modal-dialog .filtros span:hover{
    color: #FF7D14;
}
.modal-cupom .modal-dialog .modal-content {
    padding: 30px;
}
.modal-cupom .modal-dialog .div-inputs .row{
    display: flex;
    flex-wrap: wrap;
}
.modal-cupom .modal-dialog .div-inputs .col-12{
    width: 100%;
    padding: 5px;
}
.modal-cupom .modal-dialog .div-inputs .col-6{
    width: 50%;
    padding: 5px;
}
.modal-cupom .modal-dialog .div-inputs .primeira-compra{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}
.modal-cupom .modal-dialog .div-inputs .busca-usuario{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.modal-cupom .modal-dialog .div-inputs .busca-usuario .select2-container .select2-choice{
    height: 34px;
}
.modal-cupom .modal-dialog .div-inputs .busca-usuario .select2-container .select2-choice abbr{
    top: 10px;
}
.modal-cupom .modal-dialog .div-inputs .busca-usuario .select2-container .select2-choice .select2-chosen{
    padding: 4px;
}
.modal-cupom .modal-dialog .div-inputs .busca-usuario .select2-container .select2-choice .select2-arrow b{
    margin-top: 3px;
}
.modal-cupom .modal-dialog .div-inputs .primeira-compra input{
    width: 18px;
}
.div-criar-cupom {
    position: relative;
    padding-bottom: 30px;
}
.modal{overflow: overlay !important;}
.modal-backdrop { position: fixed !important; }
.modal-content{overflow: hidden !important;}

/* BOTÕES DA PARTE SUPERIOR DA TELA */
.nav-prod { margin-bottom: 10px; padding: 15px 0 0 0; }
.nav-prod > li > a { text-align: center; height: 100px; padding: 5px 30px; background: #F7F7F7; border: 1px solid #DDD; border-bottom: none; border-top-right-radius: 2em; border-top-left-radius: 1.5em; }
.nav-prod > li:hover > a > i, .nav-prod > li:hover > a { color: rgb(65, 65, 65) !important; }
.nav-icon{ margin-top: 10px; margin-bottom: 10px; color: rgb(113, 113, 113); }
.nav-prod .active > a > i, .nav-prod > li > a { color: rgb(0, 0, 0) !important; }
/**/
.tab-content > .tab-pane {
    height: 0;
    display: none;
    visibility: hidden;
}
.tab-content > .active {
    height: auto;
    display: block;
    visibility: visible;
}
</style>